import "./index/style-index.css"
import "./global-helpers/style-crud.css"
import "./global-helpers/style-global.css"
import "../order/css/style-schein.css"
// import "./client-specific/style-zsm.css"
import {Auth}                            from "@aws-amplify/auth"
import Amplify                           from "@aws-amplify/core"

import awsmobile from "../aws-exports.js"
Amplify.configure(awsmobile)

window.jQuery = $;
window.$ = $;

if ('serviceWorker' in navigator) {
    // Use the window load event to keep the page load performant
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js').then(function () {
            console.log("serviceworker loaded!");
        });
    });
}
export const client = "schein";
export const clientSettings = {
    table_prefix: "",
    active_module: "",
    client: client,
    osbModelsUrl: "https://djhyl0f3s0x9t.cloudfront.net/osb-model-images/",
    tns_loaded: "0",
    OsbGetDataLoaded: "0",
    measurementsFunctionsLoaded: "0",
    TweenMax: "0",
    authorizedUser: "false",
    order_loaded: "0",
    StorageLoaded: "0",
    navigationLoaded: "0",
    elementTriggersLoaded: "0",
    sidemenuLoaded: "0",
    basePath: "",
    orderPageVisits: 0,
}
clientSettings.active_module = "osa";
clientSettings.client = client;
$('#signInForm').on('submit', async function (e) {
    e.preventDefault();
        await signIn();
});

async function signIn() {
    let username = $("#email")
        .val();
    let password = $("#password")
        .val();
    try {
        const user = await Auth.signIn(username, password);
        console.log(user);
        window.location.href = "./homepage.html";
    }
    catch (error) {
        console.log("error signing in", error);
    }
}
